import { compose, createStore, applyMiddleware } from "redux";

import { loadState, saveState } from "./localStorage";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import appReducer from "./Redux/index";
const persistedState = loadState();

const enhancers = [];
const loggerMiddleware = createLogger();

const middleware = [thunkMiddleware, loggerMiddleware];

export const store = createStore(
  appReducer,
  persistedState,
  compose(applyMiddleware(...middleware), ...enhancers)
);

store.subscribe(() => {
  saveState({

    goldLoan:store.getState().goldLoan,
  });
});
